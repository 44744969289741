import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../styles/bantrab/components/button.module.scss'

const Button = ({
  children,
  outline,
  background,
  icon,
  large,
  rounded,
  file,
  pink,
  disabled,
  ...attrs
}) => {
  attrs.className = classNames(attrs.className,
    [
      'z-10'
    ],
    {
      [styles.button]: true,
      [styles.background]: background,
      [styles.icon]: icon,
      [styles.large]: large,
      [styles.outline]: outline,
      [styles.rounded]: rounded,
      [styles.pink]: pink,
      [styles.disabled]: disabled
    }
  )

  return (
    disabled
      ? (
        <a {...attrs} onClick={() => {}}>
          {children}
        </a>
      )
      : file
        ? (
          <a download {...attrs} >
            {children}
          </a>
        )
        : (
          <a {...attrs}>
            {children}
          </a>
        )
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  background: PropTypes.bool,
  icon: PropTypes.bool,
  large: PropTypes.bool,
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  file: PropTypes.any,
  pink: PropTypes.any,
  disabled: PropTypes.bool
}

export default Button

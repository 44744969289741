import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'

import Image from '../components/bantrab/image'
import Typography from '../components/bantrab/typography'
import Button from '../components/bantrab/button'

import NotFound from '../public/img/bantrab/decoration-not-found.png'
import NotFoundBg from '../public/img/bantrab/decoration-bannu-solid-1.svg'

const Error = _ => {
  return (
    <div
      className={
        classNames([
          'w-full',
          'h-full',
          'min-h-screen',
          'bg-gray-06',
          'flex',
          'relative',
          'flex-column',
          'p-5',
          'md:flex-row',
          'md:justify-center',
          'md:items-center'
        ])
      }>
      <NotFoundBg
        className={
          classNames([
            'absolute',
            'top-0',
            'right-0',
            'w-4/5',
            'text-turquoise-200',
            'md:h-full',
            'md:w-auto'
          ])
        } />
      <div
        className={
          classNames([
            'relative',
            'flex',
            'flex-col-reverse',
            'justify-center',
            'items-center',
            'w-full',
            'md:flex-row',
            'md:w-3/4'
          ])
        }
        style={{
          maxWidth: '1200px'
        }}>
        <div
          className={
            classNames([
              'w-full',
              'md:w-1/3'
            ])
          }>
          <div
            style={{
              maxWidth: '400px'
            }}>
            <Typography
              weight="black"
              size="six">
              ¡UPS, tenemos un pequeño problema!
            </Typography>
            <div className="h-0.5 mt-6 mb-6 bg-turquoise-500"></div>
            <Typography
              size="three">
              Lo sentimos, la página que está buscando no se pudo encontrar.
            </Typography>
            <Link
              href={'/'}>
              <a>
                <Button
                  className={
                    classNames([
                      'w-full',
                      'mt-6'
                    ])
                  }
                  background>
                  Ir a inicio
                </Button>
              </a>
            </Link>
          </div>
        </div>
        <div
          className={
            classNames([
              'w-full',
              'md:w-2/3',
              'relative',
              'text-right'
            ])
          }>
          <Image
            src={NotFound}
            width={640}
            height={426}
            layout={'intrinsic'} />
        </div>
      </div>
    </div>
  )
}

export default Error
